import React from 'react'
import styled, { css } from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import siteConfig from '../../../data/siteConfig'

const Education = ({ className, title = 'Education', text = '' }) => (
    <div>
        <h1>{title}</h1>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>    
)


export default styled(Education)`

  position: relative;
  :before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    margin: 70px 0 0 -1px;
    width: 1px;
    height: calc(100% - 70px);
    background: ${({ theme }) => theme.colors.primary};
  }


`